import React, {useEffect, useState, useRef} from 'react'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Confetti from './Confetti'
import styled from 'styled-components'

const Left = styled.div`
width:100%;
height:100%;
position:absolute;
display:block;
text-align:center;
background:var(--pink--light);
color:var(--pink--dark);
font-weight:var(--f-w--bold);
text-shadow: 0.25em 0.25em 0px rgba(255,255,255 ,0.95);

  section{
  clip-path: url(#myCurve);
  display:flex;
  align-items: center;
  align-content:center;
  justify-content:center;
  flex-wrap:wrap;
  position:fixed;
  left:0;
  top:0;
  z-index:5;
  opacity:0;
  pointer-events:none;
  height:60%;
  width:calc(100% - 4rem);
  padding:0 2rem;
  transform:translateX(0%);

    @media only screen and (min-width: 960px) {
    height:100%;
    width:75%;
    transform:translateX(-5%);
    }

    &.isActive{
      opacity:1;
      pointer-events:all;
    }
  }

  .animate{
  opacity:0;
  transform:translateY(-1rem);
  }
  .input-wrap{
  position: relative;
  display:grid;
  }

  .input{
  background:none;
  border:0;
  text-align:center;
  font-weight:var(--f-w--bold);
  text-shadow: 4px 4px 0px rgba(255,255,255 ,0.95);
  opacity:0;
  transform:translateY(4rem);
  color:var(--pink--dark);
  z-index:10;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  transition:all 0.5s ease-in-out ;

    &:focus{
    outline:none;

      &::placeholder{
      opacity: 0;
      }
    }

    
  }

  .invisible{
    opacity: 0 !important;
  }

  .placeholder{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    transition:all 0.5s ease-in-out ;
  }

  .icon-wrap{
    svg{
      filter: drop-shadow(3px 3px 0px rgba(255,255,255 ,0.95));
    }
  }

  .label{
  margin-top:0.5em;
  transition:opacity 0.5s ease-in-out;
  width: 100%;
  text-align: center;
  align-self:flex-end;
  min-height:50px;
  }

  .buttons{
  margin-top:2rem;
  
    button{
    background:none;
    border:0;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    
      span{
      position: absolute;
      text-align:center;
      font-weight:var(--f-w--bold);
      /* text-shadow: 0.25em 0.25em 0px rgba(255,255,255 ,0.95); */
      color:var(--pink--dark);
      pointer-events:none;
      }

      svg{
      height:auto !important;
      max-width:100px;
      pointer-events:none;
      
        @media only screen and (min-width: 1200px) {
        max-width:157px;
        }

        path{
          transition:all 0.5s ease-in-out;
        }
      }

      &:hover{
        svg{
          path{
            stroke: var(--pink--dark)
          }
        }
      }
    }
  }
`


export default function LeftSection({showContent, setShowContent}) {

  const [active, setActive] = useState()
  const [name, setName] = useState('')
  const [rsvp, setRsvp] = useState('')
  const formRef = useRef()

  const setNameOnEnter = (e) => {
    const value = e.target.value;
    setName(value)
    
    if(e.key === 'Enter'){
      setActive('section3')
      if(typeof window !== 'undefined'){
        const inputs = Array.from(document.querySelectorAll('input'))
        inputs.map(input => input.blur())
      }
    }    
  }

  const setRsvpOnClick = (e) => {
    const value = e.target.value
    setRsvp(value)
    setActive('section4')
  }


  useEffect(() => {
    if(rsvp !== ''){
      handleSubmit()
    }
  }, [rsvp])
  
  function encode(data) {
    const encodedData = Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
    console.log(encodedData)
    return encodedData
  }
  const handleChange = (e) => {
    
  } 

  
  const handleSubmit = () => {
    console.log({
      name: name,
      rsvp: rsvp,
    })
    const form = formRef.current
     
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
          "form-name": form.getAttribute("name"),
          ...{
            name: name,
            rsvp: rsvp,
          },
      }),
    })
    .then(() => {
      // typeof window !== "undefined" && window.gtag("event", "Contact Form Submitted", {
      //   'event_category': 'contact'
      // })
    })
    .catch(error => alert(error))
  }


  useEffect(() => {
    if(showContent === true){
      const timer = setTimeout(() => setActive('section1'), 500);
      const timer2 = setTimeout(() => setActive('section2'), 3600);
      return () => clearTimeout(timer);
    }
  }, [showContent]);
  
  return (
    <Left>
      <Section1 isActive={active === 'section1' && showContent === true ? true : false} />
      <Section2 isActive={active === 'section2' && showContent === true ? true : false} setNameOnEnter={setNameOnEnter} name={name} />
      <Section3 isActive={active === 'section3' && showContent === true ? true : false} setRsvpOnClick={setRsvpOnClick} name={name} />
      <Section4 isActive={active === 'section4' && showContent === true ? true : false} areTheyComing={rsvp} setShowContent={setShowContent} />
      <Confetti areTheyComing={rsvp} showContent={showContent} />    

      <form id="rsvp" hidden data-netlify="true" ref={formRef}  data-netlify-honeypot="bot-field" name="rsvp" method="POST">
        <input type="hidden" name="form-name" value="rsvp" />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={(e) => handleChange(e)} />
          </label>
        </p>
        <input type="text" name="name" disabled value={name} />
        <input type="text" name="rsvp" disabled value={rsvp} />
      </form>  
    </Left>
  )
}
