import React, {useEffect, useState} from 'react'
import gsap from 'gsap'

export default function Section2({isActive, setNameOnEnter, name}) {

  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    const animateThis = gsap.utils.toArray(['.section-2 .animate'])
    if(isActive === true){
      gsap.to(animateThis, {opacity:1, y:0, stagger:0.5, duration:0.5})
      const timer = setTimeout(() =>  {
        // gsap.to('.section-2 .label', {opacity:0, y:'-10rem', duration:0.5})
        gsap.to('.section-2 .input', {opacity:1, y:0, duration:0.5})
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isActive])


  return (
    <section className={`section-2 animateWrap ${isActive === true ? 'isActive' : ''}`}>
      <div className="input-wrap">
        <label className={`animate placeholder text-l width-full ${isFocused === false ? '' : 'invisible' }`}>What is your child's name?</label>
        <input className={`input animate text-l width-full ${isFocused === false && name !== '' ? 'invisible' : '' }`} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} onKeyUp={(e) => setNameOnEnter(e)} type="text" />
      </div>
      <label className='label animate text-s'>
        {isFocused === true && name !== '' ? 'Press enter to continue' : '' }
        {isFocused === true ? '' : 'Click or press text above to type'}
      </label> 
      
    </section>
  )
}
