import React, {useEffect} from 'react'
import gsap from 'gsap'
import styled from 'styled-components'
import Blob from '../images/blob.inline.svg'
import BlobMobile from '../images/blob-mobile.inline.svg'


const Right = styled.div`
position:fixed;
right:0;
bottom:0;
width: 100%;
height:40%;

@media only screen and (min-width: 960px) {
height:100%;
width:40%;
top:0;
bottom:inherit;
}

  svg{
  width:100%;
  height:100%;
  position: absolute;
  left:0;
  top:0;
  z-index:10;
  }

  video{
  height:100%;
  opacity:0;
  width:100%;
  object-position: center;
  object-fit:cover;
  position: absolute;
  right:0;
  bottom:-2px;
  

    @media only screen and (min-width: 960px) {
    width: 100%;
    bottom:0;
    object-position:left bottom;
    }

  }

  .blob-mobile{
  height:100%;
  }

  .mobile{
  display:block;
  /* height:calc(100% + 2px) !important; */
  /* margin-top:-2px; */

    @media only screen and (min-width: 960px) {
    display:none;
    }

  }
  .desktop{
  display:none;

    @media only screen and (min-width: 960px) {
    display:block;
    }

  }
`

export default function RightSection({showContent}) {

  useEffect(() => {
    if(showContent === true){
      gsap.to('.right video', {opacity:1, duration:1})
      gsap.to('.right .blob-desktop', {width:'40%', duration:1.5})
      gsap.to('.right .blob-mobile', {height:'10%', duration:1.5})
    }else{
      gsap.to('.right video', {opacity:0})
      gsap.to('.right .blob-desktop', {width:'100%'})
      gsap.to('.right .blob-mobile', {height:'100%', duration:1.5})
    }
  },[showContent])
  return (
    <Right className='right'>
      <Blob className="desktop blob-desktop" />
      <BlobMobile className="mobile blob-mobile" />
      <video 
      autoPlay
      muted
      loop
      className='desktop video-desktop'
      src="https://player.vimeo.com/progressive_redirect/playback/697390883/rendition/1080p?loc=external&signature=153412a99de71ab41f5cdbc5adde0ec7a66322e1576dc0c0f23cbe23ba915a60"
      ></video>
      <video 
      autoPlay
      muted
      loop
      className='mobile video-mobile'
      src="https://player.vimeo.com/progressive_redirect/playback/698172228/rendition/720p?loc=external&signature=e164174e6a91450dcdde0aa0be3e8f2b03b5d770530bf2261c1619f92e059d4c"
      ></video>
    </Right>
  )
}
