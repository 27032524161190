import React, {useEffect} from 'react'
import gsap from 'gsap'

export default function Section1({isActive}) {

  useEffect(() => {
    const animateThis = gsap.utils.toArray(['.section-1 .animate'])
    if(isActive === true){
      gsap.to(animateThis, {opacity:1, y:0, stagger:0.5, duration:0.5})
      const timer = setTimeout(() =>  gsap.to('.section-1', {opacity:0, y:'-10rem', duration:0.5}), 3000);
      return () => clearTimeout(timer);
    }
  }, [isActive])

  return (
    <section className={`section-1 animateWrap ${isActive === true ? 'isActive' : ''}`}>
      <span className="animate text-xl width-full">It's Eve's Birthday!</span>
      <span className="animate text-m width-full">We'd love it if you could join us!</span>
    </section>
  )
}
