import React, {useEffect, useState} from 'react'
import { Helmet } from 'react-helmet'
import LeftSection from '../sections/LeftSection'
import RightSection from '../sections/RightSection'

export default function Index() {
  const [showContent, setShowContent] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setShowContent(true), 500);
    return () => clearTimeout(timer);
  }, []);
  
  return (
    <>
      <Helmet>
        <title>Eve's Birthday Party</title>
        <meta name="robots" content="noindex nofollow" />
      </Helmet>
      <LeftSection setShowContent={setShowContent} showContent={showContent} />
      <RightSection showContent={showContent} />
    </>
  )
}
