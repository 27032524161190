import React, {useEffect, useState} from 'react'
import gsap from 'gsap'
import Blob from '../images/blob-button.inline.svg'
import {FiMapPin} from 'react-icons/fi'
import {AiOutlineCalendar} from 'react-icons/ai'

export default function Section3({name, setRsvpOnClick, isActive}) {

  useEffect(() => {
    const animateThis = gsap.utils.toArray(['.section-3 .animate'])
    if(isActive === true){
      gsap.to(animateThis, {opacity:1, y:0, stagger:0.3, duration:0.5})
      // const timer = setTimeout(() =>  gsap.to('.section-3', {opacity:0, y:'-10rem', duration:0.5}), 3000);
      // return () => clearTimeout(timer);
    }
  }, [isActive])

  const [guestName, setGuestName] = useState()

  useEffect(() => {
    if(name){
      // has a name set

      if(name.indexOf(' ') !== -1){
        // has at least one space
        setGuestName(name.substring(0, name.indexOf(' ')))
      }else{
        // has no spaces
        setGuestName(name)
      }
    }else{
      // has no name set
      setGuestName('you')
    }
  })

  return (
    <section className={`section-3 animateWrap ${isActive === true ? 'isActive' : ''}`}>
      <span className="animate text-l width-full mb">Will {guestName} be coming?</span>
      <span className="animate text-s width-full icon-wrap"><FiMapPin /> Jackaboos, Farsley</span>
      <span className="animate text-s width-full icon-wrap"><AiOutlineCalendar /> Sunday 22nd May, 4-5.30pm</span>

      <div className="animate buttons">
        <button onClick={(e) => setRsvpOnClick(e)} value={'Yes'}>
          <span className='text-m'>Yes!</span>
          <Blob />
        </button>
        <button onClick={(e) => setRsvpOnClick(e)} value={'No'}>
          <span className='text-m'>No</span>
          <Blob />
        </button>
      </div>
    </section>
  )
}
