import React, {useEffect} from 'react'
import gsap from 'gsap'

export default function Section4({isActive, areTheyComing, setShowContent}) {

  useEffect(() => {
    const animateThis = gsap.utils.toArray(['.section-4 .animate'])
    if(isActive === true){
      gsap.to(animateThis, {opacity:1, y:0, stagger:0.5, duration:0.5})
      const timer = setTimeout(() =>  setShowContent(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [isActive])

  return (
    <section className={`section-4 animateWrap ${isActive === true ? 'isActive' : ''}`}>
      {areTheyComing === 'Yes' ? <span className="animate text-xl width-full">Great! Can't wait!</span> : '' }
      {areTheyComing === 'No' ? <span className="animate width-60"><div className="text-l">No worries!</div> <div className="text-s">Thanks for letting us know.</div></span> : '' }
    </section>
  )
}
